import './App.css';
import playerimg from './user1.png'
import uzb from './uzb.png'


function App() {

  let Players = [
    {
      name: 'Akmaljon',
      number: '#17',
      position: "ST",
      ovr: 87,
      pac: 87,
      sho: 88,
      pas: 78,
      dri: 79,
      def: 23,
      phy: 79,
      games: 0,
      goals: 0,
      assists: 0,
      clearance: 0,
      saves: 0,
      penalty: 0,
      contribution: 0,
    },
    {
      name: 'Azizjon',
      number: '#8',
      position: "CAM",
      ovr: 88,
      pac: 83,
      sho: 83,
      pas: 89,
      dri: 88,
      def: 72,
      phy: 81,
      games: 0,
      goals: 0,
      assists: 0,
      clearance: 0,
      saves: 0,
      penalty: 0,
      contribution: 0,
    },
    {
      name: 'Daler',
      number: '#10',
      position: "RW",
      ovr: 89,
      pac: 84,
      sho: 84,
      pas: 85,
      dri: 91,
      def: 74,
      phy: 87,
      games: 0,
      goals: 0,
      assists: 0,
      clearance: 0,
      saves: 0,
      penalty: 0,
      contribution: 0,
    },
    {
      name: 'Sanjar',
      number: '#4',
      position: "CB",
      ovr: 89,
      pac: 89,
      sho: 81,
      pas: 82,
      dri: 86,
      def: 91,
      phy: 89,
      games: 0,
      goals: 0,
      assists: 0,
      clearance: 0,
      saves: 0,
      penalty: 0,
      contribution: 0,
    },
    {
      name: 'Umidjon',
      number: '#7',
      position: "LW",
      ovr: 88,
      pac: 82,
      sho: 84,
      pas: 83,
      dri: 90,
      def: 37,
      phy: 80,
      games: 0,
      goals: 0,
      assists: 0,
      clearance: 0,
      saves: 0,
      penalty: 0,
      contribution: 0,
    },
    {
      name: 'Sanji',
      number: '#3',
      position: "CB",
      ovr: 87,
      pac: 85,
      sho: 79,
      pas: 83,
      dri: 82,
      def: 88,
      phy: 83,
      games: 0,
      goals: 0,
      assists: 0,
      clearance: 0,
      saves: 0,
      penalty: 0,
      contribution: 0,
    },
    {
      name: 'Siroj',
      number: '#9',
      position: "ST",
      ovr: 86,
      pac: 88,
      sho: 86,
      pas: 78,
      dri: 80,
      def: 49,
      phy: 78,
      games: 0,
      goals: 0,
      assists: 0,
      clearance: 0,
      saves: 0,
      penalty: 0,
      contribution: 0,
    },
    {
      name: 'Amirjon',
      number: '#11',
      position: "LW",
      ovr: 86,
      pac: 85,
      sho: 80,
      pas: 81,
      dri: 85,
      def: 52,
      phy: 89,
      games: 0,
      goals: 0,
      assists: 0,
      clearance: 0,
      saves: 0,
      penalty: 0,
      contribution: 0,
    },
    {
      name: 'Doniyor',
      number: '#5',
      position: "CB",
      ovr: 85,
      pac: 78,
      sho: 65,
      pas: 79,
      dri: 78,
      def: 85,
      phy: 84,
      games: 0,
      goals: 0,
      assists: 0,
      clearance: 0,
      saves: 0,
      penalty: 0,
      contribution: 0,
    },
    {
      name: 'Nabijon',
      number: '#2',
      position: "RB",
      ovr: 84,
      pac: 79,
      sho: 72,
      pas: 80,
      dri: 80,
      def: 84,
      phy: 78,
      games: 0,
      goals: 0,
      assists: 0,
      clearance: 0,
      saves: 0,
      penalty: 0,
      contribution: 0,
    },
    {
      name: 'Sardor',
      number: '#6',
      position: "CDM",
      ovr: 83,
      pac: 76,
      sho: 75,
      pas: 85,
      dri: 81,
      def: 82,
      phy: 68,
      games: 0,
      goals: 0,
      assists: 0,
      clearance: 0,
      saves: 0,
      penalty: 0,
      contribution: 0,
    },
    {
      name: 'Doniyor',
      number: '#1',
      position: "CB",
      ovr: 76,
      pac: 78,
      sho: 68,
      pas: 72,
      dri: 73,
      def: 79,
      phy: 85,
      games: 0,
      goals: 0,
      assists: 0,
      clearance: 0,
      saves: 0,
      penalty: 0,
      contribution: 0,
    },

  ]

  return (
    <div className='App' >
     <div className='header'>
      Welcome to the club!
     </div>
      <div className='container'>
        {Players.map((player)=>{
         return ( <div className='player-container'>
            <div className='player-1'>  
             <img className='img' src={playerimg}></img> 
             <div className='p1'>
                <div className='p1-1'> 
                  <div className='p-num'> {player.number} </div>
                  <div> <img className='uzb' src={uzb}></img>  </div>
                </div>
                <div className='p1-2'> 
                <div className='p1-2-name'>{player.name}</div>
                <div className='p1-2-position'>{player.position}</div>
                 </div>
              </div>
            </div>
           
           
            <div className='player-2'>   
              <div className='stats-holder'>
      
              <div className='ovr-text' >OVR</div>
              <div className='ovr-rating'>{player.ovr}</div>
              
              
              </div>
              <div className='stats-holder'>
              <div >
                <div className='rate-text'>PAC |</div>
                <div className='rate-num'>{player.pac}</div>
              </div>
              <div>
                  <div className='rate-text'>GAME |</div>
                 <div className='rate-num'>{player.games}</div>
              </div>
              </div>
              <div className='stats-holder'>
              <div >
                <div className='rate-text'>PAS |</div>
                <div className='rate-num'>{player.pas}</div>
              </div>
              <div>
                  <div className='rate-text'>GOAL |</div>
                 <div className='rate-num'>{player.goals}</div>
              </div>
              </div>
              <div className='stats-holder'>
              <div >
                <div className='rate-text'>DRI |</div>
                <div className='rate-num'>{player.dri}</div>
              </div>
              <div>
                  <div className='rate-text'>ASSIST |</div>
                 <div className='rate-num'>{player.assists}</div>
              </div>
              </div>
              <div className='stats-holder'>
              <div >
                <div className='rate-text'>DEF |</div>
                <div className='rate-num'>{player.def}</div>
              </div>
              <div>
                  <div className='rate-text'>SAVE |</div>
                 <div className='rate-num'>{player.saves}</div>
              </div>
              </div>
              <div className='stats-holder'>
              <div >
                <div className='rate-text'>PHY |</div>
                <div className='rate-num'>{player.phy}</div>
              </div>
              <div>
                  <div className='rate-text'>PEN |</div>
                 <div className='rate-num'>{player.penalty}</div>
              </div>
              </div>
              <div className='stats-holder'>
      
              <div className='ovr-text' >G/A</div>
              <div className='ovr-rating'>{player.contribution}</div>
              
              
              </div>
              
              </div>


         </div>)
        })}
      </div>
    </div>
  );
}

export default App;
